* {
  margin: 0px;
  padding: 0px;
}
body {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  background-color: #000;
  /* Rectangle 306 */
}
*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
}
